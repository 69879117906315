<template>
  <div class="ibox deployment-site">
    <div class="ibox-title">
      <h2>{{ $t('cms.deployment.site.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <p>{{ $t('cms.deployment.site.note', {'domain': selectedSiteTopLevelDomain}) }}</p>

      <form role="form" @submit="onFormSubmit" name="publish-site">
        <button class="btn btn-primary ladda-button publish-site" data-style="zoom-in" type="submit">{{ $t('cms.deployment.site.button', {'domain': selectedSiteTopLevelDomain}) }}</button>
      </form>

    </div>
  </div>
</template>

<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';

import { useSiteAdmin } from '../../../composables/useSiteAdmin';

export default defineComponent({
    props: {
        
    },
    components: {
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();
      const { sites, selectedSite, selectedSiteTopLevelDomain } = useSiteAdmin(props, context);


      const listLoading:Ref<boolean> = ref(false);

      var laddaSubmit:Ladda.LaddaButton|null = null;

      onMounted(() => {
        var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=publish-site] button.publish-site' );
        laddaSubmit = Ladda.create(submitButton!);
      })

      const onFormSubmit = (evt:Event) => {
        // We prevent submit of the page
        evt.preventDefault();

        var input = {}

        var options:api.ApiVueOptions =  {
          app: app
        }

        laddaSubmit!.start();

        var path = '/api/admin/cms/site/'+selectedSite.value.site._id+'/deployment/publish-site';
        
        api.postAPI(path, input, options).then((response:any) => {
          if(response.published) {  
            if(response.site) {
              // We emit event
              context.emit('site-published', response.site);
            }
            if(response.shopContent) {
              // We emit event
              context.emit('shop-content-published', response.shopContent);
            }
          }

          laddaSubmit!.stop();
        });
        
      }

        return { 
          sites, 
          selectedSite,
          selectedSiteTopLevelDomain,
          listLoading,
          onFormSubmit
        }
    }
})
</script>