<template>
  <div class="ibox deployment-customization">
    <div class="ibox-title">
      <h2>{{ $t('cms.deployment.validation.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <p>{{ $t('cms.deployment.validation.note') }}</p>

      <a v-if="selectedSiteTopLevelDomain" class="btn btn-primary" :href="siteValidationLink" target="_blank">{{$t('cms.deployment.validation.button-site', {'domain': selectedSiteTopLevelDomain})}}</a>
      <p v-else>{{ $t('cms.deployment.validation.no-domain') }}</p>

    </div>
  </div>
</template>

<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import { useSiteAdmin } from '../../../composables/useSiteAdmin';

export default defineComponent({
    props: {
        
    },
    components: {
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();
      const { selectedSiteTopLevelDomain, siteValidationLink } = useSiteAdmin(props, context);

      const listLoading:Ref<boolean> = ref(false);

      return { 
        listLoading,
        siteValidationLink,
        selectedSiteTopLevelDomain
      }
    }
})
</script>